<script setup lang="ts">
const { t } = useT();
const { open } = useNlcModals();

const handleClick = () => {
	dispatchGAEvent({
		event: "click_button",
		button_name: "How_SC_Work",
		location: "promotions"
	});
	open("LazyOModalSuperCoinInfo");
};
</script>

<template>
	<MPromotionWrapper type="superCoins" image="/nuxt-img/super-coin-info/promo-d.jpg" @click-card="handleClick">
		<AText :size="36" :modifiers="['bold', 'center']" class="txt" as="div">
			<i18n-t keypath="How {key} work?" tag="span">
				<template #key>
					<AText class="text-coimbatore" variant="tucson" :modifiers="['lowercase', 'bold']">
						{{ t("Super Coins") }}
					</AText>
				</template>
			</i18n-t>
		</AText>
	</MPromotionWrapper>
</template>

<style lang="scss" scoped>
.txt {
	transform: translateY(-60%);
}
</style>
